import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoDefault from '../store/images/logo_horizontal.svg';
import PartnerService from '../../service/partner-config-service';
import 'assets/styles/_activate-warranty.css';


function TrackRequest() {
  const { productCodes } = useParams();
  const { t } = useTranslation();
  const [logo, setLogo] = useState(LogoDefault);
  const [loading, setLoading] = useState(true);
  const [imei, setImei] = useState('');
  const [document, setDocument] = useState('');
  const [errors, setErrors] = useState({ imei: '', document: '' });

  useEffect(() => {
    PartnerService.findAllPartners(productCodes)
      .then((response) => {
        setLogo(response.DataHelper.appIcon(data) || LogoDefault);
        setButtonColor(response.DataHelper.color(data) || '#000000');
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading partner configuration:", error);
        setLoading(false);
      });
  }, [productCodes]);



  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loaderr"></div>
        <p className="loading-text">
          {t('imei_demo.loading')}
          <span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
        </p>
      </div>
    );
  }

  return (
    <div className="container-activate">
      <main className="main-activate">
        <header className="imei-header">
          <img src={logo} alt="Logo do parceiro" className="logo-activate" />
        </header>

        <p>{t('imei_demo.click_the_button')}</p>

        <button
          className="button-86-activate"
          role="button"
        >
          {t('imei_demo.activate_warranty')}
        </button>
      </main>
    </div>
  );
}

export default TrackRequest;
