import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoDefault from '../store/images/logo_horizontal.svg';
import PartnerService from '../../service/partner-config-service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/_imeiDemo.css';

function ImeiDemo() {
  const { productCode } = useParams();
  const { t, i18n } = useTranslation();
  const [logo, setLogo] = useState(LogoDefault);
  const [loading, setLoading] = useState(true);
  const [imei, setImei] = useState('');
  const [userId, setUserId] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [showThirdScreen, setShowThirdScreen] = useState(false);
  const [errors, setErrors] = useState({ imei: '', userId: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const validateInputs = () => {
    let isValid = true;

    if (!imei || imei.length !== 15) {
      setErrors((prev) => ({ ...prev, imei: t('imei_demo.invalid_imei') }));
      toast.error(t('imei_demo.invalid_imei'));
      isValid = false;
    }

    const cleanedUserId = userId.replace(/\D/g, '');
    if (!cleanedUserId || cleanedUserId.length !== 11) {
      setErrors((prev) => ({ ...prev, userId: t('imei_demo.invalid_cpf') }));
      toast.error(t('imei_demo.invalid_cpf'));
      isValid = false;
    }

    return isValid;
  };

  const fetchCallbackUrl = async (productCode) => {
    try {
      const response = await PartnerService.findCustomInterfaceByProductCode(productCode);

      if (response?.data) {
        const customInterface = response.data.custom_interface;
        const callbackPartner = customInterface?.callbackPartner;

        if (callbackPartner) {
          return callbackPartner;
        }
      }
      return null;
    } catch (error) {
      console.error("Erro ao buscar o callbackPartner:", error);
      return null;
    }
  };

  useEffect(() => {
    i18n.changeLanguage('pt');
  }, [i18n]);


  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        const response = await PartnerService.findCustomInterfaceByProductCode(productCode);
        if (response?.data) {
          const appIcon = response.data.logo;
          setLogo(appIcon || LogoDefault);
        }
      } catch (error) {
        setLogo(LogoDefault);
      } finally {
        setLoading(false);
      }
    };

    if (!isFetched) {
      fetchPartnerData();
      setIsFetched(true);
    }
  }, [isFetched, productCode]);

  const handleValidationClick = async () => {
    const payload = {
      "imei": imei,
      "productCode": productCode,
      "userId": userId,
    };

    try {
      const response = await PartnerService.validateUploadUser(payload);

      if (response.status !== 200) throw new Error();

      toast.success(t('imei_demo.validation_success'));
      return true;
    } catch (error) {
      console.error("Validation error:", error);
      toast.error(t('imei_demo.validation_failed'));
      return false;
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userId || setUserId(/\D/g, '').length !== 11) {
      toast.error(t('imei_demo.invalid_cpf'));
      setErrors((prev) => ({ ...prev, userId: t('imei_demo.invalid_cpf') }));
      return;
    }

    if (!imei || imei.length !== 15) {
      toast.error(t('imei_demo.invalid_imei'));
      setErrors((prev) => ({ ...prev, imei: t('imei_demo.invalid_imei') }));
      return;
    }

    setShowSecondScreen(true);
  };

  const handleImeiChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 15);
    setImei(value);
    if (value.length === 15) {
      setErrors((prev) => ({ ...prev, imei: '' }));
    }
  };

  const handleDocumentChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 15);
    setUserId(value);
    if (value.length === 11) {
      setErrors((prev) => ({ ...prev, userId: '' }));
    }
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loaderr"></div>
        <p className="loading-text" style={{ marginTop: '50px' }}>
          {t('imei_demo.loading')}
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </p>
      </div>
    );
  }

  // Third screen
  if (showThirdScreen) {
    return (
      <div className="imei-container">
        <main className="imei-main">
          <header className="imei-header">
            <img src={logo} alt="Logo do parceiro" className="imei-logo" />
          </header>
          <p>{t('imei_demo.warranty')}</p>
          <button
            className="button-86"
            role="button"
            style={{ marginTop: '50px' }}
            onClick={async () => {
              const callbackUrl = await fetchCallbackUrl(productCode);

              if (callbackUrl) {

                window.location.href = callbackUrl;
              } else {

                toast.error(t('imei_demo.callback_not_found'));
              }
            }}
          >
            {t('imei_demo.track_request')}
          </button>

        </main>
      </div>
    );
  }


  // Second screen
  if (showSecondScreen) {
    return (
      <div className="imei-container">
        <main className="imei-main">
          <header className="imei-header">
            <img src={logo} alt="Logo do parceiro" className="imei-logo" />
          </header>

          <p>{t('imei_demo.click_the_button')}</p>

          <button
            className="button-86"
            role="button"
            style={{ marginTop: '50px' }}
            onClick={() => setShowThirdScreen(true)}
          >
            {t('imei_demo.activate_warranty')}
          </button>
        </main>
      </div>
    );
  }

  // First screen
  return (
    <div className="imei-container">
      <main className="imei-main">
        <header className="imei-header">
          <img src={logo} alt="Logo do parceiro" className="imei-logo" />
        </header>

        <p style={{ marginTop: '50px' }}>{t('imei_demo.instruction')}</p>

        <form className="imei-form" onSubmit={handleSubmit}>
          <div className="imei-inputGroup" style={{ marginTop: '25px' }}>
            <label htmlFor="imei">{t('imei_demo.imei_label')}</label>
            <input
              id="imei"
              type="text"
              placeholder={t('imei_demo.imei_placeholder')}
              className="imei-input"
              value={imei}
              onChange={handleImeiChange}
            />
            {errors.imei && <span className="error-message">{errors.imei}</span>}

            <a
              href="intent://settings/#Intent;action=android.settings.DEVICE_INFO_SETTINGS;end"
              className="imei-link"
              style={{ marginTop: '10px' }}
              onClick={(e) => {
                if (!/Android/i.test(navigator.userAgent)) {
                  e.preventDefault();
                  toast.error(t('imei_demo.android_only_message'));
                }
              }}
            >
              {t('imei_demo.how_to_find_imei')}
            </a>
          </div>

          <div className="imei-inputGroup" style={{ marginTop: '25px' }}>
            <label htmlFor="document">{t('imei_demo.document_label')}</label>
            <input
              id="document"
              type="text"
              placeholder={t('imei_demo.document_placeholder')}
              className="imei-input"
              value={userId}
              onChange={handleDocumentChange}
            />
            {errors.document && <span className="error-message">{errors.document}</span>}
          </div>


          <button
            className="button-86"
            role="button"
            style={{ marginTop: '50px' }}
            disabled={isButtonDisabled}
            onClick={async (e) => {
              e.preventDefault();
              if (!validateInputs()) return;

              setIsButtonDisabled(true);
              const isValid = await handleValidationClick();
              if (isValid) {
                setShowSecondScreen(true);
              } else {
                setIsButtonDisabled(false);
              }
            }}
          >
            {t('imei_demo.activate_button')}
          </button>


        </form>
      </main>
    </div>
  );
}

export default ImeiDemo;
