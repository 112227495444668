import { Chart } from "react-google-charts";
import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import CollateralService from "../../service/collateral-service";
import PartnerService from "../../service/partner-config-service";
import * as Authentication from '../../utils/helpers/authentication-helper';
import ProductConfigHelper from '../../utils/helpers/product-config-helper';
import { withTranslation } from "react-i18next";

function ChartVisualizeDataByDate(props) {
  const { t } = props;
  const isAdmin = Authentication.isAdmin();
  const itemsOption = ["Month", "Year"];
  const [visibilityOption, setVisibilityOption] = useState(itemsOption[0]);
  const [data, setData] = useState([]);
  const [productCodes, setProductCodes] = useState(itemsOption[0]);
  const [productCode, setProductCode] = useState(!isAdmin ? ProductConfigHelper.getProductCode() : 'demo');
  const [isFiltersSelected, setIsFiltersSelected] = useState(true);


//Front part treatment
  const options = {
    title: t("billing.total_by_period"),
    curveType: "function",
    legend: { position: "bottom" },
    chartArea: { width: "85%", height: "70%" },
    vAxis: { title: t("billing.amount"), gridlines: { color: "#e5e5e5" } },
    colors: ["#4285F4", "#DB4437", "#F4B400", "#0F9D58"],
    lineWidth: 3,
    pointSize: 5,
    animation: {
      startup: true,
      duration: 700,
      easing: "out",
    },
    annotations: {
      textStyle: {
        fontSize: 13,
        color: '#000',
        auraColor: 'none',
      },
    },
  };

  const isDataValid = data && data.length > 1;

  //Logic for loading partners
  useEffect(() => {
    const loadPartners = async () => {
      try {
        const result = await PartnerService.findAllPartners(0, 500, 'DESC');
        if (result && result.data.content.length > 0) {
          const filteredProductCodes = result.data.content
            .map((item) => ({ value: item.productCode, label: item.productCode }));

          filteredProductCodes.sort((a, b) => a.label.localeCompare(b.label));
          setProductCodes(filteredProductCodes);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadPartners();
  }, []);

  // Loads data based on visibility option and selected partner
  useEffect(() => {
    const fetchData = async () => {
      if (isFiltersSelected) {
        try {
          const result = await CollateralService.visualizeDataByDate(visibilityOption, productCode);
          const array = result.data || [];
          if (array.length > 0) {
            const dataView = [
              ["Date", "Available", { role: 'annotation' }, "Locked", { role: 'annotation' }, "Released", { role: 'annotation' }, "Active", { role: 'annotation' }],
              ...array
                .filter(row => row.length >= 5 && row.every(col => col !== undefined))
                .sort((a, b) => new Date(a[0]) - new Date(b[0]))
                .map((row) => {
                  const date = new Date(row[0]);
                  const formattedDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                  });
                  return [
                    formattedDate,
                    row[1], row[1]?.toString() || "",
                    row[2], row[2]?.toString() || "",
                    row[3], row[3]?.toString() || "",
                    row[4], row[4]?.toString() || ""
                  ];
                }),
            ];
            setData(dataView);
          } else {
            setData([]);
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setData([]);
        }
      }
    };

    fetchData();
  }, [visibilityOption, productCode, isFiltersSelected]);

  //Updates the productCode when a partner is selected
  const handlePartnerChange = (event) => {
    setProductCode(event.value);
    //Checks if both filters are selected
    if (event.value && visibilityOption) {
      setIsFiltersSelected(true);
    }
  };

  //Updates visibility and checks if both filters are selected
  const handleVisibilityOptionChange = (event) => {
    setVisibilityOption(event.value);
    if (productCode && event.value) {
      setIsFiltersSelected(true);
    }
  };

  return (
    <div className="group-components">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown
          className="form-select"
          options={itemsOption}
          onChange={handleVisibilityOptionChange}
          value={visibilityOption}
          placeholder={t("billing.select_period")}
        />

        {isAdmin && (
          <>
            <p className="silver label-same-size" style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}
            >
              {t('billing.partner')}
            </p>
            <div className="input-container" style={{ width: '220px' }}>
              <Dropdown
                className="form-select-without-margin-horizontal"
                options={productCodes}
                onChange={handlePartnerChange}
                value={productCode}
                placeholder={t('billing.select')}
              />
            </div>
          </>
        )}
      </div>

      {isDataValid && isFiltersSelected ? (
        <Chart
          className="chart"
          chartType="LineChart"
          data={data}
          options={options}
          width={"100%"}
          height={"400px"}
        />
      ) : (
        <div className="no-data-container">
          <div className="alert">{t("billing.no_data_found")}</div>
        </div>
      )}
    </div>
  );
}

const ChartVisualizeDataByDateTranslated = withTranslation()(ChartVisualizeDataByDate);
export default ChartVisualizeDataByDateTranslated;
