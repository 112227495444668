import API from 'config/api';

const PartnerService = {
  findPartnerConfig: () => {
    return API.get('/partner-config');
  },

  /**
   * Updates the partner logo.
   *
   * @param logo The logo URL.
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateLogo: (logo) => API.post(
    `/partner-config/updateLogo`, logo
  ),

  updateLockMessage: (lockMessage) => API.put(
    `/partner-config/lock-options?lockMessage=${encodeURIComponent(lockMessage)}`
  ),

  updateLockType: (lockType) => API.put(
    `/partner-config/lock-options?lockType=${encodeURIComponent(lockType)}`
  ),

  updatePreLocked: (preLocked) => API.put(
    `/partner-config/lock-options?preLocked=${encodeURIComponent(preLocked)}`
  ),

  updatePhoneNumber: (phoneNumber) => API.put(
    `/partner-config/interface?phoneNumber=${phoneNumber}`
  ),

  updatePermissions: (permissions) => API.put(
    `/partner-config/permissions`, permissions
  ),

  updatePaymentPage: (paymentPage) => API.put(
    `/partner-config/interface?paymentPage=${encodeURIComponent(paymentPage)}`
  ),

  updateBackgroundColor: (backgroundColor) => API.put(
    `/partner-config/interface?backgroundColor=${encodeURIComponent(backgroundColor)}`
  ),

  updateHelpPage: (helpPage) => API.put(
    `/partner-config/interface?helpPage=${encodeURIComponent(helpPage)}`
  ),

  /**
   * @param {string[]} allowedPhoneNumbers
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateAllowedPhoneNumbers: (allowedPhoneNumbers) => {
    const phoneNumbers = (allowedPhoneNumbers)
      ? allowedPhoneNumbers.map(phoneNumber => `allowedPhoneNumbers=${phoneNumber}`)
      : ['allowedPhoneNumbers='];
    return API.put(`/partner-config/lock-options?${phoneNumbers.join('&')}`);
  },

  /**
   * @param {string[]} allowedApplications
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateAllowedApplications: (allowedApplications) => {
    const applications = (allowedApplications)
      ? allowedApplications.map(application => `allowedApplications=${application}`)
      : ['allowedApplications='];
    return API.put(`/partner-config/lock-options?${applications.join('&')}`);
  },

  updateEmergencyNumbers: (emergencyNumbers) => {
    const numbers = emergencyNumbers.map(number => `emergencyNumbers=${number}`);
    return API.put(`/partner-config/lock-options?${numbers.join('&')}`);
  },

  updateBlockOnSimCardRemoval: (blockOnSimCardRemoval) => API.put(
    `/partner-config/lock-options?blockOnSimCardRemoval=${blockOnSimCardRemoval}`
  ),

  regenerateApiKey: () => API.post(
    '/partner-config/regenerate-api-key'
  ),

  updateWebhook: (webhook) => API.put(
    `/partner-config/interface?webhook=${encodeURIComponent(webhook)}`
  ),

  updateWebhookKG: (webhookUrlCustomer) => API.put(
    `/partner-config/interface?webhookUrlCustomer=${encodeURIComponent(webhookUrlCustomer)}`
  ),

  updatePreCreateCollateral: (preCreateCollateral) => API.put(
    `/partner-config/interface?preCreateCollateral=${encodeURIComponent(preCreateCollateral)}`
  ),

  updateHasBiometric: (hasBiometric) => API.put(
    `/partner-config/interface?hasBiometric=${encodeURIComponent(hasBiometric)}`
  ),

  getLockType: () => API.get(
    '/partner-config/details/lock-type'
  ),

  createPartner: (partnerData, activationFee, maintenanceFee) => API.post(
    `/partner-config/create?activationFee=${activationFee}&maintenanceFee=${maintenanceFee}`, partnerData
  ),

  updatePartner: (partnerData) => API.put(
    `/partner-config/update`, partnerData
  ),

  updateAppConfiguration: (appConfiguration) => API.put(
    `/partner-config/app-configuration`, appConfiguration
  ),

  updateConfigurationKG: (configurationKG) => API.put(
    `/partner-config/configuration-kg`, configurationKG
  ),

  findPartners: () => API.get(
    '/partner-config/items'
  ),

  findFees: (productCode) => API.get(
    `/partner-config/fees?productCode=${encodeURIComponent(productCode)}`
  ),

  updateFees: (productCode, fees) => API.put(
    `/partner-config/${productCode}/fees`, fees
  ),

  approve: (productCode) => API.put(
    `/partner-config/${productCode}/approve`
  ),

  findConfigByCode: (productCode) => API.get(
    `/partner-config/${productCode}/configuration`
  ),

  findCustomInterfaceByProductCode: (productCode) => API.get(
    `/partner-config/${productCode}/custom-interface`
  ),

  findAllPartners: (page, size, direction) => API.get(
    `partner/partners?page=${page}&size=${size}&direction=${direction}`
  ),

  findByName: (page, size, direction, name) => API.get(
    `partner/partners-by-name?page=${page}&size=${size}&direction=${direction}&name=${name}`
  ),

  findByStep: (page, size, direction, step) => API.get(
    `partner/partners-by-step?page=${page}&size=${size}&direction=${direction}&step=${step}`
  ),

  updateAllowedApps: (apps) => API.put(
    `/partner-config/update-allowed-apps`, apps
  ),

  getCompatibleVersions: () => API.get(
    `/partner-config/compatible-versions`
  ),

  validateUploadUser: (payload) => API.post(
    '/partner-config/validate-upload-user', payload),


};

export default PartnerService;
